import { mapGetters } from "vuex";
import { hasAccess } from "../../../config/admin.route.config";
import rules from "../../../utils/rules";
// import api from "@/api/api.service";
import companyapi from "@/api/company.service";
import EventBus from "../../../utils/EventBus";
export default {
  name: "customer-dialog",
  components: {},
  props: ["isCustombtn"],
  data() {
    return {
      // isCustombtn: false,
      userdialog: false,
      appsheetId: "",
      isOEMCompany: false,
      OEMCompanyName: "",
      parentcompaniesList: [],
      companySelected: "",
      loading: false,
      valid: true,
      details: "",
      rules: rules,
      companyName: "",
      trackunitToken: "",
      TrackunitTokenError: "",
      name: "",
      email: "",
      phone: "",
      trackunitnumber: "",
      clientId: "",
      clientSecret: "",
      userName: "",
      password: "",
      userRole: "",
      isAccess: {
        reportAbug: true,
        reportRequest: true,
      },
      openedPanel: null,
    };
  },
  computed: {
    ...mapGetters(["toolbarDense", "navbarShow", "roles"]),
    toggleNavbarIcon() {
      return this.navbarShow
        ? "mdi-format-indent-decrease"
        : "mdi-format-indent-increase";
    },
  },
  created() {
    EventBus.$on("customer-got-triggered", () => {
      (this.rules = rules), (this.userdialog = true);
    });
  },
  mounted() {
    this.userId = this.$store.getters.userId;
    this.isCustomer = this.roles == "Customer" ? true : false;
    this.isAccess = hasAccess.dashboard[this.roles]
      ? hasAccess.dashboard[this.roles]
      : this.isAccess;
  },
  methods: {
    getOEMCompanyName() {
      if(this.isOEMCompany == true && this.OEMCompanyName == ""){
      this.OEMCompanyName = this.companyName;
      }
    },
    toggleNavbar() {
      this.$store.dispatch("NavbarToggle");
    },
    async SaveOnBoardingReport() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let oemCompanyName = "";
        if(this.isOEMCompany == true){
          oemCompanyName = this.OEMCompanyName
        }else{
          oemCompanyName = "";
        }
        let payload1 = {
          name: this.companyName,
          accountNo: this.trackunitnumber,
          contactName: this.name,
          isOEMCompany: this.isOEMCompany,
          oemCompanyName : oemCompanyName,
          contactPhone: this.phone,
          contactEmail: this.email,
          parentId: this.companySelected,
          tuClassicAccount: {
            token: this.trackunitToken,
          },
          appSheetAccount: {
            id: this.appsheetId,
            name: "",
          },
          irisAccount: {
            clientId: this.clientId,
            clientSecret: this.clientSecret,
            userName: this.userName,
            password: this.password,
          },
          userId: this.userId,
        };
        // console.log(payload1);
        try {
          let apiResponse = await companyapi.createCustomerOnboarding(payload1);
          if (apiResponse.isSuccess == true) {
            this.loading = false;
            this.userdialog = false;
            this.$refs.form.reset();
            this.$toast.success(apiResponse.systemMessage);

            // if (this.$route.name == "CustomerOnboardingList") {
            //   this.$router.go();
            // } else {
            //   this.$router
            //     .push({
            //       path: "/customer-onboarding/list",
            //     })
            //     .catch(() => {});
            // }
          } else {
            this.loading = false;

            this.$toast.error("Unable to create customer");
          }
        } catch (err) {
          this.loading = false;
          console.log(err);
          // if (err.response.status == 400) {
          //   this.TrackunitTokenError = err.response.data;
          //   this.$toast.error(err.response.data);
          // } else this.$toast.error("Unable to create customer" + err);
        }
        // let payload = {
        //   userId: this.userId,
        //   companyName: this.companyName,
        //   trackUnitToken: this.trackunitToken,
        //   trackUnitAccountNumber: this.trackunitnumber,
        //   name: this.name,
        //   email: this.email,
        //   phone: this.phone,
        //   // details: this.details,
        //   iris_ClientID: this.clientId,
        //   iris_ClientSecret: this.clientSecret,
        //   iris_Username: this.userName,
        //   iris_Password: this.password,
        // };
        // try {
        //   let apiResponse = await api.createCustomerOnboarding(payload);
        //   if (apiResponse) {
        //     this.loading = false;
        //     this.userdialog = false;
        //     this.$refs.form.reset();
        //     this.$toast.success(apiResponse.systemMessage);

        //     // if (this.$route.name == "CustomerOnboardingList") {
        //     //   this.$router.go();
        //     // } else {
        //     //   this.$router
        //     //     .push({
        //     //       path: "/customer-onboarding/list",
        //     //     })
        //     //     .catch(() => {});
        //     // }
        //   }
        // } catch (err) {
        //   this.loading = false;
        //   if (err.response.status == 400) {
        //     this.TrackunitTokenError = err.response.data;
        //     this.$toast.error(err.response.data);
        //   } else this.$toast.error("Unable to create customer" + err);
        // }
      }
    },
    async CompanyList() {
      try {
        let response = await companyapi.getAllCustomers();
        if (response) {
          // this.parentcompaniesList = response.data;
          // console.log(response.data);
          if (this.roles == "User") {
            this.parentcompaniesList = response.data.filter(
              (c) => this.$store.getters.customerIds.includes(c.id)
            );
          } else {
            this.parentcompaniesList = response.data;
          }
        }
      } catch (error) {
        //this.$toast.error("Unable to load the data" + error);
      }
    },
    ClearDialog() {
      this.openedPanel = null;
      this.userdialog = false;
      this.$refs.form.reset();
      (this.companyName = ""),
        (this.trackunitToken = ""),
        (this.name = ""),
        (this.email = ""),
        (this.phone = ""),
        (this.trackunitnumber = "");
      this.clientId = "";
      this.clientSecret = "";
      this.userName = "";
      this.password = "";
      this.isOEMCompany = false;
      (this.OEMCompanyName = "");
    },
  },
};
